<template>
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
  <div class="page-template-collab collection">
    <popup-prescription v-if="displayPrescription" :cart_item="currentCartItem" />
  
    <div class="collab-section product">
        <div class="left">
                <div class="text_preview"  v-if="displayTextPreview==true">
                    <div class="gravure_preview">
                        {{gravure}}
                    </div>
                    <img class="gravure_img" :src="photo" />
                </div>
                
                <div v-show="displayTextPreview==false && loaded && photosLoaded">
                    <div class="my-slider" id="slider-content"  >
                        
                    </div>
                    <div id="prev"><img src="../../../public/assets/images/arrow.svg" /></div>
                    <div id="next"><img src="../../../public/assets/images/arrow.svg" /></div>
                </div>

                <div id="slider-content-slides" class="slider-content-hide">
                    <div class="carousel-cell" v-for="(photo,index) in photos" :key="index" ><img :src="photo" /></div>
                </div>
           
            
        </div>
        <div class="right">
           
            <div >
                <div class="collab-title">{{glass.name}}</div>
                <div class="price"><span v-if="current_price==glass.price">A partir de</span> <span class="amount">{{current_price}}€</span></div>

                <p class="contenudesk">
                {{glass.description}}
                </p>

                <div class="personnalisation">
                    
                    <div class="middle">
                        <div class="select-block">
                            <div class="select" @click="displayGlassTypes = !displayGlassTypes">
                                {{activeGlassTypeName}}

                            </div>
                            <div class="option-list" v-if="displayGlassTypes">
                                <div class="option" @click="selectGlassType('nocorrection','Sans correction',0)">0€ - Sans correction</div>
                                <div v-for="glass_type in glass_types" :key="glass_type.id">
                                    <div class="option" v-if="userScans.length>1 && glass_type.id!=5" @click="selectGlassType(glass_type.id,glass_type.name,glass_type.price)">
                                        {{glass_type.price}}€ - {{glass_type.name}}
                                    </div>
                                    <div class="option disabled" v-else-if="glass_type.id!=5">
                                        {{glass_type.price}}€ - {{glass_type.name}} (disponible sur-mesure)
                                    </div>
                                    
                                </div>
                            
                            </div>
                            
                        </div>
                        <div class="select-block">
                            <div class="select" @click="displayGlassOptions = !displayGlassOptions">
                                {{activeGlassOptionName}}  <span v-if="activeGlassOption!=null"><img src="../../../public/assets/images/color.png" width="15" v-if="activeGlassOption.tints.length>1" /></span>
                            </div>
                            <div class="option-list" v-if="displayGlassOptions">
                                <div class="option" @click="selectGlassOption(null,'Personnaliser',0, 0)">Aucune option</div>
                                <span v-for="(glass_option, index) in glass_options" :key="glass_option.id">
                                    <div v-if="hasGlassType(activeGlassType, index)==true && glass_option.id!=8" @click="selectGlassOption(glass_option.id,glass_option.name, index, glass_option.price)" class="option">{{glass_option.price}}€ - {{glass_option.name}}
                                    <img src="../../../public/assets/images/color.png" width="15" v-if="glass_option.id==4" />
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="personnalisation-options">
                        <div class="options">
                            <div class="option-title" v-bind:class="{active : activePersonnalisation=='color'}" @click="selectPersonnalisation('color')">COULEUR</div>
                            <div class="option-title" v-bind:class="{active : activePersonnalisation=='texture'}" @click="selectPersonnalisation('texture')">TEXTURE</div>
                            <div class="option-title" v-bind:class="{active : activePersonnalisation=='branch'}" @click="selectPersonnalisation('branch')">BRANCHES</div>
                            <div class="option-title" v-if="displayTintChoice" v-bind:class="{active : activePersonnalisation=='tint'}" @click="selectPersonnalisation('tint')">VERRES</div>
                            <div class="option-title" v-bind:class="{active : activePersonnalisation=='gravure'}" @click="selectPersonnalisation('gravure')">GRAVURE<span class="price-option">(+10€)</span></div>
                            <div class="option-underline" :style="'left:'+underlinePosition+'%;'"></div>
                        </div>
                        <div class="personnalisation-content" v-if="activePersonnalisation=='texture'">
                        
                            <div class="item" v-for="(texture, index) in glass.textures" :key="texture.id">
                                <img :src="texture.photo" />
                                <div class="title-item">{{texture.name}}</div>
                                <div class="add" @click="selectTexture(texture.id, texture.price,index)" v-bind:class="{active : activeTexture==texture.id}">
                                    <div class="price-item">{{texture.price}}€</div>
                                    <div class="add-button">
                                        <span v-if="activeTexture==texture.id">Appliqué</span>
                                        <span v-else>Appliquer</span>
                                    </div>
                                </div>
                            </div>
                            <div class="subitems" v-if="activeTexture!=4">
                                <div class="item texture_style"  @click="selectTextureStyle(texture_style.id, texture_style.price)" v-bind:class="{active : activeTextureStyle==texture_style.id}" v-for="texture_style in activeTextureObject.texture_styles" :key="texture_style.id">
                                    <div class="title-item">{{texture_style.name}}</div>
                                <div class="img"> <img :src="texture_style.photo" /></div>
                                </div>
                            </div>
                        </div>
                        <div class="personnalisation-content" v-if="activePersonnalisation=='color'">
                            <div class="item color" v-for="(color, index) in glass.colors" @click="selectColor(color.id, color.price, index)" :key="color.id" v-bind:class="{active : activeColor==color.id}">
                                <div class="img">
                                <img :src="color.photo" />
                                </div>
                                <div class="title-item">{{color.name}}</div>
                                
                            </div>
                        </div>
                        <div class="personnalisation-content" v-if="activePersonnalisation=='tint' && displayTintChoice">
                            <div class="tint-options">
                                <div class="option-title" v-bind:class="{active : activeTintCategory=='all'}" @click="selectTintCategory('all',0)">Toutes les couleurs</div>
                                <div class="option-title" v-for="(tint_category,index) in tint_categories" :key="tint_category.id" v-bind:class="{active : activeTintCategory==tint_category.id}" @click="selectTintCategory(tint_category.id,index)">{{tint_category.name}}</div>

                            </div>
                            <span v-if="activeTintCategory=='all'">
                                <span v-for="(tint, index) in activeTints" :key="tint.id">
                                    <div  class="item" >
                                        <img :src="tint.photo" />
                                        <div class="title-item">{{tint.name}}</div>
                                        <div class="add" @click="selectTint(tint.id, tint.price)" v-bind:class="{active : activeTint==tint.id}">
                                            <div class="price-item">{{tint.price}}€</div>
                                            <div class="add-button">
                                                <span v-if="activeTint==tint.id">Appliqué</span>
                                                <span v-else>Appliquer</span>
                                            </div>
                                        </div>
                                    </div>
                                </span>
                            </span>
                            <span v-else>
                                <span v-for="(tint, index) in actualTints" :key="tint.id">
                                    <div class="item" >
                                        <img :src="tint.photo" />
                                        <div class="title-item">{{tint.name}}</div>
                                        <div class="add" @click="selectTint(tint.id, tint.price)" v-bind:class="{active : activeTint==tint.id}">
                                            <div class="price-item">{{tint.price}}€</div>
                                            <div class="add-button">
                                                <span v-if="activeTint==tint.id">Appliqué</span>
                                                <span v-else>Appliquer</span>
                                            </div>
                                        </div>
                                    </div>
                                </span>
                            </span>
                        
                        </div>
                        <div class="personnalisation-content branchs" v-if="activePersonnalisation=='branch'">
                            <div class="item" v-for="(branch, index) in glass.branchs" :key="branch.id" >
                                <span v-if="hasBranch(index)==true">
                                <img :src="branch.photo" />
                                <div class="title-item">{{branch.name}}</div>
                                <div class="add" @click="selectBranch(branch.id, branch.price)" v-bind:class="{active : activeBranch==branch.id}">
                                    <div class="price-item">{{branch.price}}€</div>
                                    <div class="add-button">
                                        <span v-if="activeBranch==branch.id">Appliqué</span>
                                        <span v-else>Appliquer</span>
                                    </div>
                                </div>
                                </span>
                            </div>
                        </div>
                        <div class="personnalisation-content" v-if="activePersonnalisation=='gravure'">
                        <input type="text" class="input-gravure"  :maxlength="max" v-model="gravure" />
                        Grave jusqu’a 20 caractères (lettres, chiffres, et symboles simples uniquement) 
                        <span v-if="(max - gravure.length)<20">({{max - gravure.length}} restants)</span>
                        </div>
                        <div class="actions">
                            <span class="price_total">{{current_price}}€</span>
                            <a class="add-to-cart" @click="addToCart(glass.id,$event)">Ajouter au panier<span class="arrow"></span></a>
                        </div>
                    </div>
                    <br />
                    
                </div>

             <p class="contenumob">
            {{glass.description}}
            </p>
           
        </div>
            
        </div>
    </div>

    <div v-show="loaded">

    
    <br /><br /><br /><br /><br /><br /><br />
    
    </div>
  </div>
    </b-overlay>
</template>

<script>

import jQuery  from 'jquery';

import emitter from 'tiny-emitter/instance'
import {APIRequest} from "@simb2s/senseye-sdk"
import { tns } from "../../../node_modules/tiny-slider/src/tiny-slider"
import PopupPrescription from './components/PopupPrescription.vue';
import {BModal, BButton, VBModal,BOverlay, BCard, BCardText, BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, } from 'bootstrap-vue'


export default {
    name: 'Collection',
    components: {
        PopupPrescription,
        BOverlay
        
    },
    computed: {
        collectionWatch: function () {
            return [this.activeTexture, this.activeTint, this.activeColor, this.activeBranch,this.activeTextureStyle]
        },
        current_price: function () {

            if(this.gravure!='' && this.gravure!=null)
            {
                let price=this.glass.price*1 + this.activeGlassOptionPrice*1 + this.activeGlassTypePrice*1 + this.activeColorPrice*1 + this.activeTexturePrice*1 + this.activeBranchPrice*1 + this.activeTintPrice*1 + 10*1
                if(this.activeTextureStylePrice!=0 && this.activeTextureStylePrice!=null) {
                    price+=this.activeTextureStylePrice
                }
                return price
            }
            else
            {
                let price= this.glass.price*1 + this.activeGlassOptionPrice*1 + this.activeGlassTypePrice*1 + this.activeColorPrice*1 + this.activeTexturePrice*1 + this.activeBranchPrice*1 + this.activeTintPrice*1
                if(this.activeTextureStylePrice!=0 && this.activeTextureStylePrice!=null) {
                    price+=this.activeTextureStylePrice
                }
                return price
            }
            
        },
    },
    watch:{
    photos:function()
    {
        this.$nextTick(() => {
        if(this.slider!=null)
        {
           
            let info = this.slider.getInfo();
            this.currentIndex = info.index;
            this.slider.destroy()
        }
        jQuery('#slider-content').html(jQuery('#slider-content-slides').html())
        this.tinySliderOptions.startIndex=this.currentIndex
        this.slider = tns(
            this.tinySliderOptions
        );
              
             
        })
        
    },
     collectionWatch: async function () {
        if(this.loaded==true || this.favorite!=null)
        {
            this.photosLoaded=false
            if(this.activeTint!=null)
            {
                 var request = "?glass="+this.glass.id+"&color="+this.activeColor+'&texture='+this.activeTexture+'&branch='+this.activeBranch+'&tint='+this.activeTint;
            }
            else
            {
                 var request = "?glass="+this.glass.id+"&color="+this.activeColor+'&texture='+this.activeTexture+'&branch='+this.activeBranch+'&tint=5';
            }


            if(this.activeTextureStyle!=null) {
                request+="&texture_style="+this.activeTextureStyle
            }
           
            let photos= await APIRequest.list_with_request('photos',request)
            let photo=photos[0]
            this.photos=[]
            
            for(let i=0;i<photo.length;i++)
            {
                this.photos.push(photo[i].photo)
            }
            await this.$forceUpdate();
            
            if(photo.length>0)
            {
                this.photo = photo[0].photo
                this.currentPhoto = this.photo
            }
            this.photosLoaded=true
        }
     }
    },
    data() {
        return {
            loading:true,
        displayPrescription:false,
        currentCartItem:null,
        cart:null,
        selectedScan:localStorage.senseye_b2b_selected_scan,
        saved:false,
        activeTints:[],
        photosLoaded:true,
        favoriteShare:null,
        tinySliderOptions: {
            container: '.my-slider',
            mouseDrag: true,
            navPosition:'bottom',
            loop: false,
            items: 1,
            gutter: 0,
            swipeAngle: 45,
            nav:true,
            controls:true,
            prevButton:'#prev',  // Node reference
            nextButton: '#next',
            startIndex:0,
        },
        creationSliderOptions: {
            container: '.slider-creations',
            mouseDrag: true,
            navPosition:'bottom',
            autoplay:true,
            speed:1000,
            autoplayTimeout:3000,
            autoplayHoverPause:true,
            autoplayButton:false,
            loop: true,
            items: 2,
            center:true,
            gutter: 20,
            swipeAngle: 45,
            nav:false,
            controls:true,
            prevButton:'#prev-creation',  // Node reference
            nextButton: '#next-creation',
            startIndex:0,
            responsive: {
               
                760: {
                items: 4
                }

            },
        },
        slider_creation:null,
        optionsSelected:false,
        max:20,
        currentIndex:0,
        loaded:false,
        displayTintChoice:false,
        activeTintCategory:'all',
        currentPhoto:'',
        displayTextPreview:false,
        glass_types:[],
        tint_categories:[],
        glass_options:[],
        displayGlassTypes:false,
        displayGlassOptions:false,
        glass:[],
        gravure:'',
        activeTextureStyleObject:null,
        activeTextureStyle:null,
        activeTextureStylePrice:0,
        activeTextureObject:null,
        activeTexture:null,
        activeColor:null,
        activeBranch:null,
        activeTint:null,
        activeTexturePrice:0,
        activeColorPrice:0,
        activeBranchPrice:0,
        activeTintPrice:0,
        controller: null,
        activePersonnalisation:'color',
        tween: null,
        scene: null,
        email:null,
        currentOption:'colors',
        showModal:false,
        currentImage:'',
        currentColor:'color0',
        showDevices:false,
        activeToggle:'size',
        activeSize:'m',
        activeGlassTypePrice:0,
        activeGlassOptionIndex:null,
        activeGlassOptionPrice:0,
        activeGlassTypeName:'Type de verre',
        activeGlassType:'nocorrection',
        photo:'',
        currentPhotos:[],
        photos:[],
        underlinePosition:0,
        slider:null,
        togglePosition:50,
        glassId:null,
        userScans:[],
        
        displayScans:false,
        activeGlassOption:null,
        activeGlassOptionName:'Personnaliser',
        cart:null,
        actualTints:[],
        favorite:null,
        favoriteId:null
      }
    },
    methods:{
        async displaySharePopup() {
            this.displayLoadingShare=true
            let options={}
            options.branch={}
            options.glass={}
            options.glass.id=this.glass.id
            options.amount=this.current_price
            options.branch.id=this.activeBranch
            options.color={}
            options.color.id=this.activeColor
            options.texture={}
            options.texture.id=this.activeTexture
            if(this.activeTint!=null)
            {
                options.tint={}
                options.tint.id=this.activeTint
            }
            else
            {
                options.tint=null
            }
            
            if(this.activeGlassType && this.activeGlassType!="nocorrection")
            {
                options.glass_type={}
                options.glass_type.id=this.activeGlassType
            }
            else
            {
                options.glass_type=null
            }

            
            if(this.activeGlassOption)
            {
                options.glass_option={}
                options.glass_option.id=this.activeGlassOption.id
            }
            else
            {
                options.glass_option=null
            }
            options.engraving_text=this.gravure
        
            
                options.scan={}
                options.scan.id=this.selectedScan.id
                options.size=null
            
            

             if(this.gravure!=null && this.gravure!="")
            {
                options.engraving_text=this.gravure
              
            }
            else
            {
                options.engraving_text=""
            }

            this.favoriteShare = await APIRequest.create('favorites',options)
            this.displayShare=true
            this.displayLoadingShare=false
        },
        
        selectTintCategory(id,index){
            this.activeTintCategory=id
            this.actualTints=this.tint_categories[index].tints
        },
        hasBranch(index_branch){
              
            let hasbranch=false
          
            if(this.glass.branchs[index_branch].color.id==this.activeColor)
            {
                hasbranch=true
            }  
            return hasbranch
        },
        async hasGlassOption(glass_option, index_tint){
            if(glass_option==null)
            {
                return false
            }
            else
            {
                
                let tint_detail = APIRequest.detail('tints',this.glassTints[index_tint].id)
                let glass_options=tint_detail.glass_options
                let hasglass=false
                for(let i=0;i<glass_options.length;i++)
                {
                    if(glass_options[i].id==glass_option)
                    {
                        hasglass=true
                    }
                }
                return hasglass
            }
        },
        hasGlassType(glass_type, index_option){
            if(glass_type=="nocorrection")
            {
                return true
            }
            else
            {
                let glass_types=this.glass_options[index_option].glass_types
                let hasglass=false
                for(let i=0;i<glass_types.length;i++)
                {
                    if(glass_types[i].id==glass_type)
                    {
                        hasglass=true
                    }
                }
                return hasglass
            }
        },
        
        async selectGlassOption(glass_option,name,index, price){

            if(glass_option!=null)
            {
                this.activeGlassOption=await APIRequest.detail("glass-options", glass_option) 
            }
            else {
               this.activeGlassOption=glass_option
            }

            
            this.activeTints=[]
            this.activeGlassOptionName=name
            this.activeGlassOptionPrice=price
            this.activeGlassOptionIndex=index
            if(glass_option==null)
            {
                // on select le verre transparent
                this.displayTintChoice=false
                this.activeTint=null
               
                this.activeTintPrice=0
                if(this.activePersonnalisation=="tint")
                {
                    this.selectPersonnalisation('color')
                }
            }
            else
            {
                // on verifie si on n'affiche que les verres transparents ou non
                if(this.glass_options[index].transparent_only==true)
                {
                    this.displayTintChoice=false
                    this.activeTint=null
                    
                    this.activeTints=this.activeGlassOption.tints
                    this.activeTintPrice=0
                    if(this.activePersonnalisation=="tint")
                    {
                        this.selectPersonnalisation('color')
                    }
                }
                else
                {      
                    this.displayTintChoice=true
                    // on affiche l'onglet verres
                    this.selectPersonnalisation('tint')
                    this.activeTint=null
                    this.activeTints=this.activeGlassOption.tints
                    this.activeTintPrice=0
                }
            }
            this.displayGlassOptions=false
        },
       
        
        async getGlassTypes()
        {
            let glass_types=await APIRequest.list('glass-types')
            this.glass_types=glass_types[0];
            
        },
        async getGlassOptions()
        {
            let glass_options=await APIRequest.list('glass-options')
            this.glass_options=glass_options[0];
        },
       
        selectGlassType(type,name,price){
            this.displayGlassTypes=false
            this.activeGlassType=type
            this.activeGlassTypeName=name
            this.activeGlassTypePrice=price
            this.activeTint=null
            this.activeTintPrice=0
            this.selectGlassOption(null,'Personnaliser',0, 0);
        },
        selectPersonnalisation(personnalisation){
            this.displayTextPreview=false
            this.photo=this.currentPhoto
        
            if(personnalisation=='color')
            {
                this.underlinePosition=0;
            }
            else if(personnalisation=='texture')
            {
                this.underlinePosition=20;
            }
            else if(personnalisation=='branch')
            {
                this.underlinePosition=40;
            }
            else if(personnalisation=='tint')
            {
                this.underlinePosition=60;
            }
            else if(personnalisation=='gravure')
            {
                this.displayTextPreview=true
                if(this.displayTintChoice==true)
                {
                    this.underlinePosition=80;
                }
                else
                {
                    this.underlinePosition=60;
                }
                for(let i=0;i<this.glass.branchs.length;i++)
                {
                    if(this.activeBranch==this.glass.branchs[i].id)
                    {
                        this.photo = this.glass.branchs[i].photo
                    }
                }
            }
            this.activePersonnalisation=personnalisation
        },
        selectColor(color, price, index){
            this.activeColor=color

            this.activeColorPrice=price
            this.activeBranch=this.glass.colors[index].branchs[0].id
             this.activeBranchPrice=this.glass.colors[index].branchs[0].price
        },
        selectBranch(branch, price){
            this.activeBranch=branch
            this.activeBranchPrice=price
        },
        selectTint(tint, price){
            this.activeTint=tint
            this.activeTintPrice=price
        },
        selectTextureStyle(texture_style, price){
            this.activeTextureStyle=texture_style
            this.activeTextureStylePrice=price
        },
        selectTexture(texture, price,index){
            this.activeTexture=texture
            this.activeTexturePrice=price
            this.activeTextureObject=this.glass.textures[index]
            
            if(this.activeTextureObject.texture_styles.length>0)
            {
                this.activeTextureStyle=this.activeTextureObject.texture_styles[0].id
                this.activeTextureStylePrice=this.activeTextureObject.texture_styles[0].price
            }
            else {
                this.activeTextureStyle=null
                this.activeTextureStylePrice=0
            }
        },

        async getGlass()  { 
            this.glass=await APIRequest.detail('glasses',this.glassId)
            this.photo=''

            this.activeTexture=this.glass.textures[0].id
            this.activeTextureObject=this.glass.textures[0]
            this.activeTexturePrice=this.glass.textures[0].price
            
            if(this.glass.textures[0].texture_styles.length>0)
            {
                this.activeTextureStyle=this.glass.textures[0].texture_styles[0].id
                this.activeTextureStylePrice=this.glass.textures[0].texture_styles[0].price
            }

            this.activeColor=this.glass.colors[0].id
            this.activeColorPrice=this.glass.colors[0].price
            this.activeBranch=this.glass.branchs[0].id
            this.activeBranchPrice=this.glass.branchs[0].price
            
            this.photos=this.glass.photos
            let photo=this.glass.photos[0]
            photo=photo.replace('[','');
            this.photo=photo
            this.currentPhoto=photo
           
        },
        async getTintCategories()  { 
            let tint_categories=await APIRequest.list('tint-categories')
            let categories=tint_categories[0]
            this.tint_categories=[]
            let indexcat=0

            for(let i=0;i<categories.length;i++)
            {
                let request = "?tint_category="+categories[i].id
                let available_tints_request=await APIRequest.list_with_request('tints',request)
                let available_tints=available_tints_request[0]
                let request2 = "?glass="+this.glass.id
                let tints_request=await APIRequest.list_with_request('tints',request2)
                let glass_tints = tints_request[0]
                this.glassTints = glass_tints
                let cat_available=false
                
                for(let j=0;j<available_tints.length;j++)
                {
                    for(let k=0;k<glass_tints.length;k++)
                    {
                        if(available_tints[j].id==glass_tints[k].id)
                        {
                            if(cat_available==false)
                            {
                                let item=categories[i]
                                item.tints=[]
                                item.tints.push(glass_tints[k])
                                this.tint_categories.push(item)
                                cat_available=true
                            }
                            else
                            {
                                this.tint_categories[indexcat].tints.push(glass_tints[k])
                            }
                        }
                    }
                    
                }
                if(cat_available==true)
                {
                    indexcat++
                }
            }
        },
        
        chooseOption(option)
        {
            this.currentOption=option
            this.currentColor='color1'
        },
        
        chooseColor(color)
        {
            this.currentColor=color
        },
        
        async addToCart(product,$event)
        {
            this.loaded=false
            this.loading=true
            if(this.activeGlassOptionName=='Personnaliser' && this.activeGlassTypeName=='Type de verre')
            {
                alert('Vous devez sélectionner un type de verre !')
                this.loading=false
            }
            else
            {
                if(this.activeTint==null && this.displayTintChoice==true)
                {
                    this.selectPersonnalisation('tint')
                    alert('Vous devez sélectionner une teinte de verre !')
                    this.loading=false
                }
                else
                {
                    let options={}
                    options.branch={}
                    options.branch.id=this.activeBranch
                    options.branch_price=this.activeBranchPrice
                    options.color={}
                    options.color_price=this.activeColorPrice
                    options.color.id=this.activeColor
                    options.texture=this.activeTexture
                    options.texture_price=this.activeTexturePrice
                    options.tint={}
                    options.tint.id=this.activeTint
                    options.tint_price=this.activeTintPrice
                    if(this.activeGlassType)
                    {
                        options.glass_type={}
                        options.glass_type.id=this.activeGlassType
                        options.glass_type_price=this.activeGlassTypePrice
                    }
                    else
                    {
                        options.glass_type=null
                        options.glass_type_price=0
                    }
                    if(this.activeTextureStyle)
                    {
                        options.texture_style={}
                        options.texture_style.id=this.activeTextureStyle
                        options.texture_style_price=this.activeTextureStylePrice
                    }
           
            
                    if(this.activeGlassOption)
                    {
                        options.glass_option={}
                        options.glass_option.id=this.activeGlassOption.id
                        options.glass_option_price=this.activeGlassOptionPrice
                    }
                    else
                    {
                        options.glass_option=null
                    
                        options.glass_option_price=0
                    }
                    
                    options.engraving_text=this.gravure
                
                    let scan=await APIRequest.detail('scans',this.selectedScan)
                    
                    options.scan={}
                    options.scan.id=scan.id
                    options.size=null
                   
                    var price 
                    if(options.engraving_text!=null && options.engraving_text!="")
                    {
                        price = this.glass.price*1 + options.color_price*1 + options.tint_price*1 + options.texture_price*1 + options.branch_price*1 + options.glass_type_price*1 + options.glass_option_price*1 + 10*1
                    }
                    else
                    {
                        price = this.glass.price*1 + options.color_price*1 + options.tint_price*1 + options.texture_price*1 + options.branch_price*1 + options.glass_type_price*1 + options.glass_option_price*1
                    }

                    console.log('price')
                    console.log(price)

                    if(this.activeTextureStylePrice!=0 && this.activeTextureStylePrice!=null) {
                        price+=this.activeTextureStylePrice
                    }
                    

                    console.log('price after')
                    console.log(price)

                    var amount=price*1;
                    let cart_item = {};
                    if(options.texture_style) {
                        cart_item.texture_style=options.texture_style
                    }
                    cart_item.scan=options.scan;
                    cart_item.color = options.color;
                    cart_item.glass_option = options.glass_option;
                    cart_item.glass_type = options.glass_type;
                    cart_item.color = options.color;
                    cart_item.texture = {};
                    cart_item.lense="";
                    cart_item.branch=options.branch;
                    cart_item.finish="";
                    cart_item.engraving_text=options.engraving_text;

                    if(options.tint!=null)
                    {
                        if(options.tint.id!==null)
                        {
                        cart_item.tint=options.tint; 
                        }
                    }
                    
                    cart_item.color=options.color;
                    cart_item.quantity=1;
                    cart_item.glass={};
                    cart_item.glass.id=this.glass.id;
                   
                    cart_item.texture.id=options.texture;
                    cart_item.measurements="";
                    cart_item.amount=price;
                    cart_item.shapediver_id=0;
                    cart_item.size=options.size

                    var Parameters = {};
                  
                    Parameters.delivery_amount = 0;
                    Parameters.delivery_method = 0;
                    Parameters.cart_items = [];
                    Parameters.amount = amount;
                    Parameters.cart_items.push(cart_item);
                    Parameters.promotion = null;
                    Parameters.user = {};
                    Parameters.user.id=scan.user.id;
                    Parameters.delivery_amount = 0;
                    Parameters.delivery_method = 0;
                    Parameters.main_user={}
                    Parameters.main_user.id=localStorage.id_user_senseye_auth

                    console.log('on crée le panier')
                    console.log(options)
                    console.log(Parameters)

                    this.cart=await APIRequest.create('carts',Parameters)
                    localStorage.cart_senseye_id=this.cart.id
                    this.currentCartItem=this.cart.id
                   
                    localStorage.cart_senseye_b2b_id=this.cart.id;

                    if(this.activeGlassType!='nocorrection')
                    {
                        this.displayPrescription=true
                       
                    }
                    else
                    {
                        this.$router.push({name:'personnalisation', params: { id: this.currentCartItem }})
                     
                    }
                }   
            }
            this.loaded=true
        },
        
    },
    async mounted()
    {
        if(this.selectedScan==null  ||  this.selectedScan=='undefined')
        {
            alert('Vous devez sélectionner un scan avant de passer à la personnalisation');
            this.$router.push({name:'scans'})
        }
        else {
            console.log("scan selected")
            console.log(this.selectedScan)
            this.localStorage=localStorage;
            this.slider = tns(
                this.tinySliderOptions
            );
            this.glassId=this.$route.params.id
            await this.getGlass();
            await this.getTintCategories()
            await this.getGlassTypes();
            await this.getGlassOptions();
            this.loaded=true
            this.loading=false
        }
    }
}
</script>
<style>
.share-section
{
    border-top:1px solid #E5E5E5;
    border-bottom:1px solid #E5E5E5;
    padding-top:10px;
    text-align: center;
    padding-bottom:10px;
}
.share-section a img
{
    height:22px;
    display: inline-block;
}
.share-section a 
{
    text-align:center;
    margin-left:15px;
    display:inline-block;
    vertical-align: top;
    margin-right:15Px;
    font-size:13px;
    color:#000;
    text-decoration: none;
}
.delivery-message
{
    padding:15px;
    background:#F8F8F8;
    font-size:15px;
    width:100%;
    text-align: center;
}
.tns-slider {
  transition-timing-function: ease-out;
}
#next-creation
{
    position:absolute;
    top:50%;
    right:10px;
    transform: translateY(-50%);
    z-index:3;
}
#prev-creation img
{
    transform:rotate(-180deg) ;
}
#prev-creation
{
    position:absolute;
    top:50%;
    left:10px;
    z-index:3;
    transform: translateY(-50%);
}
.slider button 
{
    display:none !important;
}
.slider,
.slider-creations
{
    position: relative;

}
.slider-creations.real .carousel-cell img
{
    width:358px;
    height:auto;
    border-radius: 13px;
}
.creations-content .title
{
    position: relative;
    width:90%;
    margin-left:auto;
    margin-right:auto;
    padding:0;
    font-size:70px;
    background-repeat: no-repeat;
    -webkit-background-clip: text;
    transition: all 0.5s;
    background-image: -webkit-linear-gradient(#000 50%, #000 50%);
    background-position: 0 0;
    background-size: 0% 0%;
    text-stroke: 1px #000;
    -webkit-text-stroke: 1px #000;
    color: #000;
    white-space: nowrap;
    color: transparent;
    text-decoration: none;
}
.image-collection
{
    width:100%;
    height:auto;
}
.line-bottom
{
    position: relative;
    width:90%;
    margin-left:auto;
    margin-right:auto;
    padding:0;
    display:flex;
    flex-direction: row;
}
.line-bottom .right .image
{
    position:absolute;
    left:0;
    top:0;
    width:75%;
    height:100%;
    background-position: center center;
    background-size:cover;
}
.line-bottom .left .text
{
    font-size:13px;
}
.line-bottom .left .title
{
    font-size:30px;
}
.line-bottom .left
{
    width:50%;
    padding-right:5%;
    padding-bottom:10%;
}
.line-bottom .right
{
    width:50%;
    position: relative;
    
}

.line-top
{
    position: relative;
    width:90%;
    margin-left:auto;
    margin-right:auto;
    padding:0;
    display:flex;
    flex-direction: row;
}
.line-top .left .image
{
    position:absolute;
    left:0;
    top:0;
    width:100%;
    height:100%;
    background-position: center center;
    background-size:cover;
}
.line-top .right .text
{
    font-size:13px;
}
.line-top .right .title
{
    font-size:30px;
}
.line-top .right
{
    width:50%;
    padding-left:5%;
    padding-bottom:10%;
}
.line-top .left
{
    width:50%;
    position: relative;
    
}
body .tns-nav button.tns-nav-active
{
height:3px;
}
body .tns-nav
{
    position:relative;
    top:-50px;
}
body .tns-nav button
{
width:45px !important;
border-radius:0;
height:2px;
}
.options-scan .option:hover
{
  background:rgba(16, 132, 255, 0.1);
}
.options-scan
{
    position:relative;
    left:20px;
}


.options-scan .option
{

  padding:5px;
  text-align:left;
  padding-left:15px;
  cursor: pointer;
}
.scan-choice::before
{
  position:absolute;
  content:' ';
  width:17px;
  height:17px;

  background-size:100% 100%;
  left:10px;
 
  top:10px;
  background-image:url('../../../public/assets/images/icon_scan.svg');
}
.scan-choice::after
{
  position:absolute;
  content:' ';
  width:50px;
  height:50px;
  transform: rotate(90deg);
  background-size:100% 100%;
  right:0px;
  color:rgba(16, 132, 255, 1);
  top:-7px;
  background-image:url('../../../public/assets/images/arrow_scan.svg');
}
.title-size.scan
{
    position:relative;
    left:20px;
}

.scan-choice
{
  cursor: pointer;
  background: rgba(16, 132, 255, 1) 0% 0% no-repeat padding-box;
  border-radius: 8px;
  color:#fff;
  padding-top:10px;
  padding-bottom:10px;
  position:relative;
  left:20px;
  padding-left:40px;
  padding-right:50px;
}
.collection .collab-section.product .right
{
    padding-top:80px;
}
.collection .collab-section.product
{
    padding-top:0;
}
.collection .collab-section.product .left
{
    position: relative;
    padding-left:0%;
    margin-right:5%;
    padding-right:0;
    width: 54%;
    background:#F8F8F8;
}
.collab-section.product .right p
{
    font-size:13px;
}
.collection .collab-section.product .right .collab-title
{
    text-align: left;
    display:inline-block;
    font-size:32px;
}
.collection .collab-section.product .right .price .amount
{
    font-size:32px;
}
.collection .collab-section.product .right .price
{
    float:right;
    font-size:15px;
    margin-top:0px;
}
.collection .personnalisation .toggles .toggle-bg
{
    background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    position:absolute;
    left:0;
    height:95%;
    top:50%;
    transform: translateY(-50%);
    z-index:1;
    width:50%;
    transition:all 0.3s ease-in-out;
}
.collection .personnalisation .toggles .toggle
{
    display:inline-block;
    width:49%;
    padding:9px 20px;
    position:relative;
    font-size:13px;
    z-index:2;
     vertical-align: top;
}
.collection .personnalisation .toggles
{
    background:rgba(118, 118, 128, 0.12);
    border-radius: 8px;
    text-align: center;
    position:relative;
    min-width: 240px;
    border: 1px solid rgba(0, 0, 0, 0.04);
    display:inline-block;
    width:40%;
    vertical-align: top;
}
.collection .personnalisation
{
    font-size:12px;
    margin-top:30px;
}
.size_choice
{
     text-align: center;
 display:inline-block;
   width:30%;
    vertical-align: top;
}
.size_choice  .size.active
{
    background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}
.head .info .link
{
    font-size:13px;
    position:relative;
    top:6px;
    float:right;
    font-weight: 600;
    color:rgba(103, 103, 103, 1);
}
.head .info
{
    float:right;
    display: inline-block;
    vertical-align: top;
        width: 15%;
    text-align: center;
}
.size_choice .size
{
    background: rgba(231, 232, 232, 1) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    opacity: 1;
    display:inline-block;
    border-radius:50%;
    vertical-align: top;
    cursor:pointer;
    padding:11px 16px;
    margin-left:3px;
    margin-right:3px;
    color:rgba(103, 103, 103, 1);
}
.collab-section.product .left {
    width:40%;
}
.collab-section.product .right {
    width:60%;
}
.collab-section.product {
    display:flex;
}
.title-size
{
    margin-top:-15px;
    font-size:13px;
    font-weight:600;
    color:rgba(103, 103, 103, 1);
}
.select
{
    cursor: pointer;
    color:rgba(2, 1, 1, 1);
    width:100%;
    
    opacity: 1;
    margin-bottom:10px;
    text-align: left;
    padding-left:50px;
    
    font-weight: 600;
    position: relative;
    
}
.personnalisation-options
{
    background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    margin-top:20px;
    padding:15px;

}
.personnalisation-options .options .option-underline
{
    
    height:2px;
    background:#000;
    position: absolute;
    bottom:0;
    width:20%;
    left:0;
    transition: all 0.3s ease-in-out;
}
.personnalisation-options .options .option-title
{
    cursor:pointer;
    padding:5px 10px;
    vertical-align: top;
    position:relative;
    width:20%;
    text-align: center;
    font-size:13px;
    border-bottom:1px solid rgba(103, 103, 103, 0.2);
}
.personnalisation-options .options
{
  display: flex;
  position:relative;
    flex-direction: row;
    
    align-items: flex-start;
}
.option-list .option:hover
{
    background:rgba(0, 0, 0, 0.05);
}
.option.disabled
{
    opacity:0.5;
}
.option-list .option
{
    padding-left: 50px;
    text-align: left;
    padding-top:5px;
    padding-bottom:5px;
    z-index:5;

    cursor: pointer;
}
.select-block
{
    position: relative;
    padding-top: 15px;
    padding-bottom: 6px;
    background: rgba(118, 118, 128, 0.12) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
   
    border-radius: 30px;
    opacity: 1;
    backdrop-filter: blur(50px);
    -webkit-backdrop-filter: blur(50px);
    margin-top:20px;
}
.option-list
{
    position:relative;
    left:0;
    top:0;
   
    width:100%;
    z-index:9999;
}
.select::after
{
    content:' ';
    width:16px;
    height:9px;
    background-image:url(../../../public/assets/images/arrow.svg);
    position:absolute;
    right:30px;
    top:5px;
    display:block;
}
.personnalisation-content .item.texture_style
{
    text-align: center;
    min-width: 70px;
    word-wrap:normal;
}
.personnalisation-content .item.color .img
{
 position:relative;

 width:46px;
height:46px;
border:1px solid rgba(0, 0, 0, 0.1);
border-radius: 50%;
position:relative;
}
.personnalisation-content .item.color.active .img
{

border:1px solid black;

}
.subitems
{
    margin-top:20px;
}
.personnalisation-content .item.texture_style.active .img
{
 position:relative;

 width:36px;
height:36px;
border:1px solid black;
border-radius: 50%;
position:relative;
}
.personnalisation-content .item.texture_style .img img
{
    width:30px;
     position:relative;
    left:0;
    top:2px;
    
    height:30px;
}
.personnalisation-content .item.texture_style .img
{
 position:relative;
display:inline-block;
 width:36px;
height:36px;

border-radius: 50%;
position:relative;
}

.personnalisation-content .item.color .img
{
 position:relative;

 width:46px;
height:46px;

border-radius: 50%;
position:relative;
}

.item.color .title-item
{
    margin-top:5px;
   
}
.personnalisation-content .item.color .img img
{
    position:relative;
    left:0;
    top:2px;
}

.personnalisation-content.branchs .item .add
{
    margin-left:auto;
    margin-right:auto;
    max-width:90px;
  
}

.personnalisation-content.branchs .item img
{
    width:168px;
    margin-left: auto;
    margin-right:auto;
}
.personnalisation-content .item img
{
    width:40px;
    margin-left: auto;
    margin-right:auto;
}
.personnalisation-content .item .add .add-button
{
    display: inline-block;
    vertical-align: top;
    padding-top:10px;
    color:rgba(103, 103, 103, 1);
    padding-bottom:10px;
}
.personnalisation-content .item .add .price-item
{
    display: inline-block;
    margin-right:15px;
     vertical-align: top;
     padding-top:10px;
    padding-bottom:10px;
}
.personnalisation-content .item .add.active
{
    background: rgba(231, 232, 232, 1) 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}
.personnalisation-content .item .add
{
    width:100%;
    cursor: pointer;
    display:block;
    padding-left:10px;
    padding-right:10px;
    background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 9px;
}
.actions
{
    padding-top:15px;
    padding-bottom:15px;
    border-top:1px solid #e1dede;
}
.personnalisation-content .item
{
    display:inline-block;
    vertical-align: top;
    text-align: center;
    margin-right:10px;
    margin-bottom:10px;
}
.actions .add-to-cart
{
    float:right;
}
.actions .price_total
{
    font-size: 35px;
}
.personnalisation-content
{
    padding-top:20px;
    display:block;
    width:100%;

}
.collection input.input-gravure
{
    background: rgba(255, 255, 255, 1) 0% 0% no-repeat padding-box;
    border: 0.5px solid rgba(112, 112, 112, 1);
    padding:10px 30px;
    min-width: 100%;
}
.flickity-page-dots .dot
{
   border-radius: 0;
        width: 50px;
    height: 2px;
}
.size_choice a 
{
    color:rgba(16, 132, 255, 1);
}
.size_choice
{

    font-size:13px;
}
 .personnalisation-options .options .option-title { 
      position:relative;
  }
    .personnalisation-options .options .option-title .price-option
    {
        position:absolute;
        font-size:8px;
        left:50%;
        bottom:-1px;
        transform:translateX(-50%);
    }
    .contenumob {
        display: none;
    }

    .actions .add-to-cart
    {
        padding-left:50px;
        padding-top:20px;
        position:relative;
        padding-bottom:20px;
        padding-right:80px;
        background: #000000 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #837A7A39;
        color:#fff;
        border-radius: 48px;
        opacity: 1;
    }

@media screen and (max-width:760px){

body .contenumob {
    display:block;
}
    .creations-content .title
    {
        font-size:45px;
    }
    .line-bottom
    {
        position: relative;
        width:100%;
        flex-direction: column;
    }
    .line-bottom .right .image
    {
        position:relative;
        left:0;
        top:0;
        width:100%;
        height:100%;
        height:330px;
     
    }
   
    .line-bottom .left
    {
        width:100%;
        padding-right:5%;
          order:2;
        padding-left:5%;
        padding-bottom:0%;
    }
    .line-bottom .right
    {
        order:1;
        width:100%;
        position: relative;
        
    }

    .line-top
    {
        position: relative;
        width:100%;
        margin-left:auto;
        margin-right:auto;
        padding:0;
        display:flex;
        flex-direction: column;
    }
    .line-top .left .image
    {
        
        position:relative;
        left:0;
        top:0;
        width:100%;
        height:100%;
        height:330px;
    }
   
    .line-top .right
    {
        width:100%;
        padding-left:5%;
        padding-right:5%;
        padding-bottom:10%;
        padding-top:10%;
    }
    .line-top .left
    {
        width:100%;
      
        position: relative;
        
    }

    body .gravure_preview
    {
            transform: translate(-5vw,-12vw);
    z-index: 3;
    font-size: 13px;
    }
    body .text_preview
    {
            height: 50vh;
    }
    body .collection .collab-section.product .right
    {
        padding-top:30px;
    }
    body .collection .collab-section.product .left
    {
        margin-right:0;
        width:100%;
    }
    body .tns-nav
    {
        bottom:0 !important;
        top:0 !important;
    }
     body .collab-section.product .actions a.add-to-cart .arrow::before, body .collab-section.product .actions a.add-to-cart .arrow::after
    {
width: 15px;
    
    height: 15px;
    
    }
    body .collab-section.product .actions a.add-to-cart .arrow
    {
width: 25px;
    min-height: 25px;
    height: 25px;
    right:16px;
    }
body .collab-section.product .actions .add-to-cart
{
        padding-left: 20px;
    padding-top: 12px;

    padding-bottom: 12px;
    padding-right: 46px;
}
body .actions .price_total
{
    float: left;
    display: block;
    width: 40%;
    font-size:23px;
    text-align:left;
}
    body .collab-section.product .actions .add-to-cart
    {
            font-size: 12px;
         
            width:165px;
           
    }
    body .personnalisation-options
{
width: 95%;
    left: 2.5%;
    position: relative;
    
}

    body .collection .collab-section.product .right .collab-title
    {
        font-size:28px;
        margin-left:5%;
    }
    body .collection .collab-section.product .right .price .amount
    {
      
        font-size:20px;
        margin-right:10px;
        color:#000;
    }
    body .collection .collab-section.product .right .price
    {
        float:none;
        font-size:11px;
         margin-left:5%;
        color:#707070;

        text-align:left;
    }
    body .products .product .bottom .product-title
    {
        font-size:13px;
    }
    body .colors-count
    {
        font-size:8px;
    }
    body .personnalisation-button
    {
        font-size:8px;
    }
    body .products .product .bottom .product-price
    {
        font-size:8px;
    }
  body .collection .personnalisation .toggles
  {
    width:50%;
  }
  body .collection .personnalisation .head
  {
      margin-top:25px;
      padding-left:5%;
      padding-right:5%;
position:relative;
  }
   body .collection .personnalisation .middle
  {
      padding-left:5%;
      padding-right:5%;
  }
  body .collection .personnalisation .toggles .toggle
  {
          padding: 9px 10px;
    
    font-size: 11px;
  }
  body .collection .personnalisation .size_choice
  {
    width:50%;
    margin-top:25px;
  }
 
  .personnalisation-options .options .option-title
  {
      font-size:10px;
       padding: 5px 4px;
       
  }
  .personnalisation
  {
      clear:both;
  }
  body .head .info
  {
      position:absolute;
      float:none;
      width:auto;
      display:inline-block;
      margin-left:auto;
      margin-right:auto;
      top:0;
      left:auto;
      right:7%;
      margin-top:0;
    
  }
}
.text_preview .gravure_img
{
    position:absolute;
    top:50%;
    width:100%;
    transform:translateY(-50%);
}
.text_preview
{
    position:relative;
    height:80vh;
    width:100%;
}
.gravure_preview
{
      
    text-shadow: 1px 1px rgba(255, 255, 255, 0.5), -1px -1px #444;
       position: absolute;
    top: 50%;
    left: 24%;
        transform: translate(-5vw,-4.9vw);
    z-index: 3;
        font-size: 18px;
    font-weight: 600;
    letter-spacing: 1px;
    color: #000;
    mix-blend-mode:overlay;
}
.carousel-cell
{
    
}
.tint-options .option-title.active
{
    border-bottom:2px solid black;
}
.collab-section.product .actions .add-to-cart
{
    font-size:15px;
}
.tint-options .option-title
{
    cursor:pointer;
    padding:5px 10px;
    vertical-align: top;
    display:inline-block;
    margin-top:-10px;
    white-space: nowrap;
    position:relative;
    width:auto;
    text-align: center;
    font-size:11px;
    border-bottom:1px solid rgba(103, 103, 103, 0.2);
}
.select img
{
     display:inline-block;
    margin-left:5px;
    vertical-align:bottom;
}
.option-list .option img
{
    display:inline-block;
    margin-left:5px;
    vertical-align:bottom;
}
.item .add 
{

font-size:8px;
}
.item.color:hover .title-item
{
opacity:1;
}
.item.color.active .title-item
{
opacity:1;
}
.item.color .title-item
{
opacity:0;

}
.title-item
{
    font-size:11px;
    margin-top:3px;
margin-bottom:3px;
}
.slider-content-hide
{
    display:none;
}
#prev[aria-disabled='true'],#next[aria-disabled='true']
{
    opacity:0.4;
}
#next
{
    transform:translateY(-50%) rotate(-90deg);
    right:10px;
}
#prev
{
    transform:translateY(-50%) rotate(90deg);
    left:10px;
}
#prev,#next
{
position:absolute;
top:50%;

}
.collection .collab-section.product .left
{
    max-height:100vh;
}
.tns-nav{
    text-align: center;
}
.tns-nav button
{
    display: inline-block;
    width: 10px;
    height: 10px;
    margin: 0 8px;
    background: #333;
    border-radius: 50%;
    opacity: .25;
    cursor: pointer;
    padding:0;
}
.tns-nav button.tns-nav-active
{
    opacity:1;
}
.collection .collab-section.product .left .carousel-cell img
{
   
    width:100%;
    height:auto;
    margin-left:auto;
    margin-right:auto;
}
.loader
{	

	background:rgba(255,255,255,0.8);
	position:absolute;
	left:0;
	top:0;
	width:100%;
	height:100%;

	z-index:6;
}
 .loader img
{
	width:200px;
	position:absolute;
	top:50%;
	left:50%;
	transform:translate(-50%,-50%);
}
@media screen and (max-width: 760px) {
    body .collection .collab-section.product .actions
    {
        text-align: left !important;
    }
    body .page-template-collab.collection .actions .price_total
    {
        display:inline-block !important;
        float:none !important;
    }
    body .page-template-collab.collection .collab-section.product .actions
    {
        display:block !important;
    }
}
@media screen and (min-width: 760px) and (max-width: 1000px)
{
    body .collection .collab-section.product .actions a {
        top:0 !important;
    }
    body .collection .collab-section.product .actions {
        text-align: left !important;
    }
}


	.collab-section.product .right .product_choice_link.active
	{
		opacity:1;
	}
	.collab-section.product .right .product_choice_link .stock 
	{
		display:block;
		max-width:100%;
		font-size:9px;
	}
	.collab-section.product .right .product_choice_link
	{
		font-weight:700;
		font-size:12px;
		cursor:pointer;
		color:#000;
		text-decoration:none;
		opacity:0.5;
		display:inline-block;
		vertical-align:top;
		margin-right:20px;
		margin-left:20px;
		border:1px solid black;
		border-radius:20px;
		padding:10px;
	}
	.collab-section.product .right .product_choice
	{
		text-align:center;
	}
    .collab-section.product .left .row img
	{
		cursor: url(../../../public/assets/images/2021/04/plus.png) 4 12, auto;
	}

    .page-template-template-product .collab-section.product .right .price
    {
        float:right;
    }
    .page-template-template-product .collab-section.product .actions .add-to-cart
    {
        margin-left:20px;
    }
    .page-template-template-product .collab-section.product .right p.bold
    {
        font-weight:500;
    }
    .page-template-template-product .collab-section.product .right .collab-subtitle
    {
        text-align:left;
        padding-left:0;
        margin-top:0;
    }
    .page-template-template-product .collab-section.product .actions .try
    {
        background:  linear-gradient(to right top, #FFFFFFBE 0%, #603BE7 0%, #008590 70%, #0B73A7 100%);
        box-shadow: 0px 3px 6px #837A7A39;
    }

</style>